import React, { useState, useEffect } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Box, Stack, Typography, Paper, Button, Divider, Alert, CircularProgress, TextField, FormControl } from '@mui/material';
import { useAuth } from '../components/Authprovider';
import Layout from '../components/Layout';
import GoogleLogo from '../assets/google_logo.svg';
import PulseBackground from '../assets/pulse.svg'; // Import the SVG

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL;

const HomePage = () => {
  const { profile, setProfile } = useAuth();
  const navigate = useNavigate();
  const [showWaitlistAlert, setShowWaitlistAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [submissionStatus, setSubmissionStatus] = useState(null);
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    if (profile?.status === 'waitlist') {
      setShowWaitlistAlert(true);
    } else if (profile) {
      navigate('/app');
    }
    wakeUpBackEnd();
  }, [profile, navigate]);

  const wakeUpBackEnd = async () => {
    try {
      const call = await axios.get('/api/homepage-load');
      console.log(call);
    } catch (error) {
      console.error('Error waking up backend:', error);
    }
  };

  const login = useGoogleLogin({
    flow: 'auth-code',
    onSuccess: async ({ code }) => {
      setLoading(true);
      try { 
        const tokens = await axios.post('/api/auth/google', { 
          code,
        }, {
          withCredentials: true,
        });
        localStorage.setItem('tokens', JSON.stringify(tokens.data));
        setProfile({
          ...profile,
          tokens: tokens.data,
        })
        navigate('/app');
      } catch (error) {
        console.error('Login failed:', error);
      } finally {
        setLoading(false);
      }
    },
    onError: (error) => {
      console.log('Login Failed:', error);
      setLoading(false);
    }
  });
  
  const handleJoinWaitlist = async () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setSubmissionStatus("Veuillez entrer une adresse email valide.");
      return; // Stop the function if the email is not valid
    }

    try {
      const response = await axios.post('/api/joinwaitlist', { email });
      if (response.data) {
        setSubmissionStatus(response.data.message);
        setEmail('');
        setShowForm(false);
      }
    } catch (error) {
      console.error('Error joining waitlist:', error);
      setSubmissionStatus("Erreur lors de l'ajout à la liste d'attente.");
    }
  };

  return (
    <div className="intro-block" style={{ background: `no-repeat center / 90% url(${PulseBackground})`, backgroundSize: 'cover', height: '100vh', width: '100vw' }}>
      <Layout>
        <div style={{
          position: 'absolute', left: '50%', top: '50%',
          transform: 'translate(-50%, -50%)', minWidth: '300px'}}
        >
          <Paper variant='outlined' style={{ padding: '1rem' }}>
            <Stack direction="column" alignItems="center" spacing={2} maxWidth="md">
              <Box>
                <Typography variant='h6'>Uniquement sur invitation</Typography>
              </Box>
              <Box>
                {loading ? (
                  <CircularProgress />
                ) : (
                  <Button variant="outlined" onClick={login} className='Glogin'>
                    <Stack direction="row" spacing={1}>
                      <img src={GoogleLogo} alt="" />
                      <Typography>Se connecter avec Google</Typography>
                    </Stack>
                  </Button>
                )}
              </Box>
              {showWaitlistAlert && (
                <Alert severity="info" >
                  Vous n'avez pas accès à l'application. Vous pourrez rejoindre la liste d'attente... quand il y en aura une ! Merci de votre patience.
                </Alert>
              )}
              <Divider variant="middle" flexItem>ou</Divider>
              {!showForm ? (
                <Button variant="outlined" onClick={() => setShowForm(true)}>
                  Rejoindre la liste d'attente
                </Button>
              ) : (
                <FormControl>
                  <TextField
                    label="Adresse email"
                    variant="outlined"
                    value={email}
                    size="small"
                    type="email"
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    margin="normal"
                  />
                  <Button variant="contained" color="primary" style={{ marginTop: '1rem' }} onClick={handleJoinWaitlist}>
                    Rejoindre la liste d'attente
                  </Button>
                </FormControl>
              )}
              {submissionStatus && <Alert severity="info" style={{ marginTop: '1rem' }}>{submissionStatus}</Alert>}
            </Stack>
          </Paper>
        </div>
      </Layout>
    </div>
  );
};

export default HomePage;
