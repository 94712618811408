import { useState } from 'react';
import axios from 'axios';

const usePastEvents = (profile) => {
  const [pastEvents, setPastEvents] = useState([]);

  const fetchPastEvents = async () => {
    const res = await axios.get(`/api/events/past?google_id=${profile.googleuser.id}`);
    setPastEvents(res.data);
  };

  return { pastEvents, fetchPastEvents };
};

export default usePastEvents;

