import React from 'react';
import { Stack, FormControl, TextField, FormHelperText, ToggleButton, ToggleButtonGroup, Button as MuiButton } from '@mui/material';

const UserContactForm = ({ contact, setContact, onSave, onCancel }) => {
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setContact({ ...contact, [name]: value });
  };

  return (
    <FormControl fullWidth margin="normal">
      <TextField label="Nom" variant="outlined" name="name" value={contact.name} onChange={handleInputChange} />
      <TextField label="Adresse email" variant="outlined" name="email" value={contact.email} onChange={handleInputChange} margin="normal" type="email" />
      
      <FormHelperText>A quelle fréquence voulez-vous déjeuner ensemble ?</FormHelperText>
      <ToggleButtonGroup
        color="primary"
        value={String(contact.frequency)}
        exclusive
        onChange={(event, newFrequency) => {
          setContact({ ...contact, frequency: Number(newFrequency) });
        }}
        fullWidth
        aria-label="Frequency"
      >
        <ToggleButton value="1">Tous les mois</ToggleButton>
        <ToggleButton value="2">Tous les 2 mois</ToggleButton>
        <ToggleButton value="3">Tous les 3 mois</ToggleButton>
        <ToggleButton value="6">Tous les 6 mois</ToggleButton>
        <ToggleButton value="12">Tous les ans</ToggleButton>
      </ToggleButtonGroup>

      {/* Last seen section, only shown when creating a new contact */}
      {!contact.usercontact_id && (
        <>
          <FormHelperText>Quand avez-vous vu ce contact pour la dernière fois ?</FormHelperText>
          <ToggleButtonGroup
            color="primary"
            value={String(contact.lastSeen)}
            exclusive
            onChange={(event, newLastSeen) => {
              setContact({ ...contact, lastSeen: Number(newLastSeen) });
            }}
            fullWidth
            aria-label="Last Seen"
          >
            <ToggleButton value="1">Il y a 1 semaine</ToggleButton>
            <ToggleButton value="4">Il y a 1 mois</ToggleButton>
            <ToggleButton value="12">Il y a 3 mois</ToggleButton>
            <ToggleButton value="24">Il y a 6 mois</ToggleButton>
            <ToggleButton value="52">Je ne sais plus</ToggleButton>
          </ToggleButtonGroup>
        </>
      )}

      <Stack direction="row" alignItems="stretch">
        <MuiButton variant="contained" onClick={onSave} style={{ marginTop: '1rem' }}>
          Enregistrer
        </MuiButton>
        <MuiButton variant="outlined" onClick={onCancel} style={{ marginTop: '1rem', marginLeft: '1rem' }}>
          Annuler
        </MuiButton>
      </Stack>
    </FormControl>
  );
};

export default UserContactForm;