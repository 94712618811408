import React, { useState } from 'react';
import { Paper, Button, Dialog, DialogActions, DialogContent, Stack, Typography, Snackbar } from '@mui/material';
import Slide from '@mui/material/Slide';
import { useAuth } from './Authprovider';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'; // Import the calendar icon

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EmailDialog = ({ open, onClose, contactName, emailTo, emailBody, onSend, restaurant }) => {
  const { profile } = useAuth();
  const [snackbarOpen, setSnackbarOpen] = useState(false); // Add this line

  const handleCopy = () => {
    navigator.clipboard.writeText(emailBody).then(() => {
      setSnackbarOpen(true); // Open the Snackbar instead of showing an alert
    });
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleSend = async () => {
    const eventDetails = {
      emailTo: emailTo, 
      userEmail: profile.googleuser.email,
      restaurant: restaurant,
    };

    try {
      const response = await fetch('http://localhost:3001/api/googleevent/create', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(eventDetails),
      });

      if (response.ok) {
        setSnackbarOpen(true); // Optionally, show a success message to the user
      } else {
        console.error('Failed to create event');
        // Optionally, handle errors, e.g., show an error message
      }
    } catch (error) {
      console.error('Error creating event:', error);
      // Optionally, handle errors, e.g., show an error message
    }

    onClose(); // Close the dialog
  };
  
  return (
    <>
      <Dialog open={open} onClose={onClose} TransitionComponent={Transition}>
        <DialogContent>
          <Stack spacing={2}>
            <Stack direction="row">
              <Typography style={{fontWeight: "bold"}}>De:  </Typography>
              <Typography>{`${profile?.googleuser.name} <${profile?.googleuser.email}>`}</Typography>
            </Stack>
            <Stack direction="row">
              <Typography style={{fontWeight: "bold"}}>À:  </Typography>
              <Typography>{`${contactName} <${emailTo ? emailTo : "Ajouter l'email au contact"}>`}</Typography>
            </Stack>
            <Paper sx={{p:2, maxWidth: 400, width: '100%'}}>
              <div style={{whiteSpace: 'pre-line'}}>
                {emailBody}
              </div>
            </Paper>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Annuler</Button>
          <Button onClick={handleCopy}>Copier</Button>
          <Button variant="contained" onClick={handleSend} disabled={!emailTo}>
            <CalendarTodayIcon sx = {{mr: 1}} />
            Envoyer
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message="Invitation envoyée"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      />
    </>
  );
};

export default EmailDialog;
