import { useState, useEffect } from 'react';
import axios from 'axios';

const useUserContacts = (profile) => {
  const [userContacts, setUserContacts] = useState([]);

  const fetchUserContacts = async () => {
    if (!profile?.googleuser?.id) return;

    try {
      const response = await axios.post('/api/contacts/read', {
        googleId: profile.googleuser.id,
      }, {
        headers: {
          'Authorization': `Bearer ${profile.tokens?.id_token}`, // Adjust according to your token key names
        },
        withCredentials: true,
      });
      setUserContacts(response.data);
    } catch (error) {
      console.error('Error fetching contacts:', error);
    }
  };

  useEffect(() => {
    fetchUserContacts();
  }, [profile, profile.tokens]);

  return { userContacts, fetchUserContacts };
};

export default useUserContacts;