import React from 'react';
import Layout from '../components/Layout';
import { Paper, Typography } from '@mui/material';

const PrivacyPolicy = () => {
  return (
    <Layout>
      <Paper variant='outlined' style={{ padding: '1rem' }}>
        <Typography variant='h5'>Politique de confidentialité</Typography>
        <Typography variant="caption">Lunchpulse</Typography>
        <Typography>
          <Typography variant="h6">ARTICLE 1 : PRÉAMBULE  </Typography>
          <Typography>La présente politique de confidentialité a pour but d’informer les utilisateurs du site :  </Typography>
          <Typography>	•	Sur la manière dont sont collectées leurs données personnelles. Sont considérées comme des données personnelles, toute information permettant d’identifier un utilisateur. A ce titre, il peut s’agir : de ses noms et prénoms, de son âge, de son adresse postale ou email, de sa localisation ou encore de son adresse IP (liste non-exhaustive) ; </Typography>
          <Typography>	•	Sur les droits dont ils disposent concernant ces données ;  </Typography>
          <Typography>	•	Sur la personne responsable du traitement des données à caractère personnel collectées et traitées ;  </Typography>
          <Typography>	•	Sur les destinataires de ces données personnelles ;  </Typography>
          <Typography>	•	Sur la politique du site en matière de cookies.  </Typography>

          <Typography variant="h6">ARTICLE 2 : PRINCIPES RELATIFS À LA COLLECTE ET AU TRAITEMENT DES DONNÉES PERSONNELLES   </Typography>
          <Typography>Conformément à l’article 5 du Règlement européen 2016/679, les données à caractère personnel sont :   </Typography>
          <Typography>	•	Traitées de manière licite, loyale et transparente au regard de la personne concernée ; </Typography>
          <Typography>	•	Collectées pour des finalités déterminées (cf. Article 3.1 des présentes), explicites et légitimes, et ne pas être traitées ultérieurement d'une manière incompatible avec ces finalités ; </Typography>
          <Typography>	•	Adéquates, pertinentes et limitées à ce qui est nécessaire au regard des finalités pour lesquelles elles sont traitées ; </Typography>
          <Typography>	•	Exactes et, si nécessaire, tenues à jour. Toutes les mesures raisonnables doivent être prises pour que les données à caractère personnel qui sont inexactes, eu égard aux finalités pour lesquelles elles sont traitées, soient effacées ou rectifiées sans tarder ;  </Typography>
          <Typography>	•	Conservées sous une forme permettant l'identification des personnes concernées pendant une durée n'excédant pas celle nécessaire au regard des finalités pour lesquelles elles sont traitées ; </Typography>
          <Typography>	•	Traitées de façon à garantir une sécurité appropriée des données collectées, y compris la protection contre le traitement non autorisé ou illicite et contre la perte, la destruction ou les dégâts d'origine accidentelle, à l'aide de mesures techniques ou organisationnelles appropriées.  </Typography>

          <Typography>Le traitement n'est licite que si, et dans la mesure où, au moins une des conditions suivantes est remplie : </Typography>
          <Typography>	•	La personne concernée a consenti au traitement de ses données à caractère personnel pour une ou plusieurs finalités spécifiques ;</Typography>
          <Typography>	•	Le traitement est nécessaire à l'exécution d'un contrat auquel la personne concernée est partie ou à l'exécution de mesures précontractuelles prises à la demande de celle-ci ; </Typography>
          <Typography>	•	Le traitement est nécessaire au respect d'une obligation légale à laquelle le responsable du traitement est soumis ; </Typography>
          <Typography>	•	Le traitement est nécessaire à la sauvegarde des intérêts vitaux de la personne concernée ou d'une autre personne physique ; </Typography>
          <Typography>	•	Le traitement est nécessaire à l'exécution d'une mission d'intérêt public ou relevant de l'exercice de l'autorité publique dont est investi le responsable du traitement ; </Typography>
          <Typography>	•	Le traitement est nécessaire aux fins des intérêts légitimes poursuivis par le responsable du traitement ou par un tiers, à moins que ne prévalent les intérêts ou les libertés et droits fondamentaux de la personne concernée qui exigent une protection des données à caractère personnel, notamment lorsque la personne concernée est un enfant. </Typography>

          <Typography variant="h6">ARTICLE 3 : DONNÉES À CARACTÈRE PERSONNEL COLLECTÉES ET TRAITÉES DANS LE CADRE DE LA NAVIGATION SUR LE SITE  </Typography>

          <Typography>Article 3.1 : Données collectées  </Typography>
          <Typography>Les données personnelles collectées dans le cadre de notre activité sont les suivantes :  </Typography>
          <Typography>- Nom et prénom</Typography>
          <Typography>- Adresse email</Typography>
          <Typography>- Adresse professionelle</Typography>
          <Typography>La collecte et le traitement de ces données répond à la (aux) finalité(s) suivante(s) :  </Typography>
          <Typography>- Envoi d'emails transactionnels liés à l'utilisation du service</Typography>
          <Typography>- Personnalisation des messages</Typography>
          <Typography>- Proposition de lieux proche de l'adresse donnée</Typography>
          <Typography>- Création d'événements dans le calendrier</Typography>
          <Typography>exemple : gestion de contrat, gestion de l’espace client, suivi de la qualité des services, envoi de newsletter, etc.  </Typography>

          <Typography>Article 3.2 : Mode de collecte des données </Typography>
          <Typography>Lorsque vous utilisez notre site, sont automatiquement collectées les données suivantes :  </Typography>
          <Typography>- Aucune</Typography>
          <Typography>D’autres données personnelles sont collectées lorsque vous effectuez les opérations suivantes sur la plateforme :  </Typography>
          <Typography>- Connexion : nom et prénom,  adresse email</Typography>
          <Typography>- Utilisation du service : adresse professionnelle</Typography>
          <Typography>Elles sont conservées par le responsable du traitement dans des conditions raisonnables de sécurité, pour une durée égale à la durée d'utilisation du service.</Typography>
          <Typography>La société est susceptible de conserver certaines données à caractère personnel au-delà des délais annoncés ci-dessus afin de remplir ses obligations légales ou réglementaires. </Typography>

          <Typography>Article 3.3 : Hébergement des données </Typography>
          <Typography>Le site Lunchpulse est hébergé par :  </Typography>
          <Typography>Render Services, Inc.</Typography>
          <Typography>[Dans le cas ou les données personnelles collectées par l’entreprise seraient partagées avec des tiers, insérer une clause “Article 3.4 : Transmission des données à des tiers” :  </Typography>
          “Les données peuvent être transmises à nos partenaires, ci-après énumérés :”] 
          <Typography>[Si le site est concerné par l’utilisation de cookies, préciser leur utilisation dans un Article 3.5 : Politique en matière de “cookies”] </Typography>

          <Typography variant="h6">ARTICLE 4 : RESPONSABLE DU TRAITEMENT DES DONNÉES ET DÉLÉGUÉ À LA PROTECTION DES DONNÉES  </Typography>
          <Typography>Article 4.1 : Le responsable du traitement des données  </Typography>
          <Typography>Les données à caractère personnelles sont collectées par [raison ou dénomination sociale de l’entreprise], [forme juridique] au capital de [montant du capital social], dont le numéro d’immatriculation est le [indiquez votre numéro RCS].  </Typography>
          <Typography>Le responsable du traitement des données à caractère personnel peut être contacté de la manière suivante :  </Typography>
          <Typography>Par courrier à l’adresse :  [indiquez l’adresse du siège social] ;  </Typography>
          <Typography>Par téléphone, au [numéro de téléphone] ;  </Typography>
          <Typography>Par mail : [email du responsable du traitement des données].  </Typography>


          <Typography>Article 4.2 : Le délégué à la protection des données  </Typography>
          <Typography>Le délégué à la protection des données de l’entreprise ou du responsable est : </Typography>
          <Typography>[Indiquez l’identité, l’adresse, le numéro de téléphone et/ou l’adresse email du DPO] </Typography>
          <Typography>Si vous estimez, après nous avoir contactés, que vos droits “Informatique et Libertés”, ne sont pas respectés, vous pouvez adresser une information à la CNIL. </Typography>

          <Typography variant="h6">ARTICLE 5 : LES DROITS DE L’UTILISATEUR EN MATIÈRE DE COLLECTE ET DE TRAITEMENT DES DONNÉES  </Typography>
          <Typography>Tout utilisateur concerné par le traitement de ses données personnelles peut se prévaloir des droits suivants, en application du règlement européen 2016/679 et de la Loi Informatique et Liberté (Loi 78-17 du 6 janvier 1978) :   </Typography>
          <Typography>	•	Droit d’accès, de rectification et droit à l’effacement des données (posés respectivement aux articles 15, 16 et 17 du RGPD) ;  </Typography>
          <Typography>	•	Droit à la portabilité des données (article 20 du RGPD) ; </Typography>
          <Typography>	•	Droit à la limitation (article 18 du RGPD) et à l’opposition du traitement des données (article 21 du RGPD) ;  </Typography>
          <Typography>	•	Droit de ne pas faire l’objet d’une décision fondée exclusivement sur un procédé automatisé ;  </Typography>
          <Typography>	•	Droit de déterminer le sort des données après la mort ;  </Typography>
          <Typography>	•	Droit de saisir l’autorité de contrôle compétente (article 77 du RGPD).  </Typography>
          <Typography>Pour exercer vos droits, veuillez adresser votre courrier à [Nom et adresse de l’entreprise] ou par mail à [Insérer l’adresse e-mail du Délégué à la protection des données] </Typography>
          <Typography>Afin que le responsable du traitement des données puisse faire droit à sa demande, l’utilisateur peut être tenu de lui communiquer certaines informations telles que : ses noms et prénoms, son adresse e-mail ainsi que son numéro de compte, d’espace personnel ou d’abonné.  </Typography>
          <Typography>Consultez le site cnil.fr pour plus d’informations sur vos droits. </Typography>

          <Typography variant="h6">ARTICLE 6 : CONDITIONS DE MODIFICATION DE LA POLITIQUE DE CONFIDENTIALITÉ </Typography>
          <Typography>L’éditeur du site [Nom du site] se réserve le droit de pouvoir modifier la présente Politique à tout moment afin d’assurer aux utilisateurs du site sa conformité avec le droit en vigueur.  </Typography>
          <Typography>Les éventuelles modifications ne sauraient avoir d’incidence sur les achats antérieurement effectués sur le site, lesquels restent soumis à la Politique en vigueur au moment de l’achat et telle qu’acceptée par l’utilisateur lors de la validation de l’achat.  </Typography>
          <Typography>L’utilisateur est invité à prendre connaissance de cette Politique à chaque fois qu’il utilise nos services, sans qu’il soit nécessaire de l’en prévenir formellement.  </Typography>
          <Typography>La présente politique, éditée le [date de mise en ligne], a été mise à jour le [date de modification de la politique].</Typography>
        </Typography>
      </Paper>
    </Layout>
  );
};

export default PrivacyPolicy;