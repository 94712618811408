import { useState, useEffect } from 'react';
import axios from 'axios';

const useUserRestaurants = (profile) => {
  const [userRestaurants, setUserRestaurants] = useState([]);

  const fetchUserRestaurants = async () => {
    if (!profile?.googleuser?.id) return;
    try {
      const response = await axios.post('/api/userrestaurants/read', {
        googleId: profile?.googleuser.id,
      }, {
        headers: {
          'Authorization': `Bearer ${profile.tokens?.id_token}`, // Adjust according to your token key names
        },
        withCredentials: true,
      });
      setUserRestaurants(response.data);
    } catch (error) {
      console.error('Error fetching restaurants:', error);
    }
  };

  useEffect(() => {
    fetchUserRestaurants();
  }, [profile, profile.tokens]);

  return { userRestaurants, fetchUserRestaurants };
};

export default useUserRestaurants;