import React, { useState } from 'react';
import { Button, Checkbox, Grid, Paper, Stack, TextField, Typography } from '@mui/material';

const UserSettingsSection = ({ userSettings, updateUserSettings }) => {
  const [ isEditing, setIsEditing ] = useState(false);
  const [ editableOfficeAddress, setEditableOfficeAddress ] = useState(userSettings.officeAddress);

  const handleDayChange = async (day) => {
    const updatedSelectedDays = userSettings.lunchDays.includes(day)
      ? userSettings.lunchDays.filter(d => d !== day)
      : [...userSettings.lunchDays, day];
    updateUserSettings({ officeAddress: userSettings.officeAddress, lunchDays: updatedSelectedDays });
  };
  
  const saveOfficeAddress = async () => {
    updateUserSettings({ officeAddress: editableOfficeAddress, lunchDays: userSettings.lunchDays });
    setIsEditing(false);
  };

  return (
    <Paper variant='outlined' style={{ padding: '1rem' }}>
        {userSettings.lunchDays && (
      <Stack>
        <Typography variant="h6">Préférences</Typography>
        <Stack direction="row" alignItems="center">
          <Typography>Mes jours de déjeuners</Typography>
          <Typography style={{ color: "gray", paddingLeft: "1rem", fontSize: "0.8rem" }}>~ {userSettings.lunchDays.length * 45} déjeuners par an</Typography>
        </Stack>
        <Grid container direction="row" justifyContent="space-around">
          {['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi'].map((day, index) => (
            <div key={index}>
              <Checkbox
                id={day}
                name={day}
                checked={userSettings.lunchDays?.includes(day)}
                onChange={() => handleDayChange(day)}
                inputProps={{ 'aria-label': day }}
              />
              <label htmlFor={day}>{day}</label>
            </div>
          ))}
        </Grid>
        <Typography>Adresse du bureau</Typography>
        <Stack direction="row" spacing={2} alignItems="center">
          <TextField
            variant="outlined"
            size='small'
            value={editableOfficeAddress}
            onChange={(e) => setEditableOfficeAddress(e.target.value)}
            fullWidth
            disabled={!isEditing}
          />
          {isEditing ? (
            <Button variant="contained" onClick={saveOfficeAddress}>Enregistrer</Button>
          ) : (
            <Button variant="outlined" onClick={() => setIsEditing(true)}>Modifier</Button>
          )}
        </Stack>
      </Stack>)}
    </Paper>
  );
};

export default UserSettingsSection;