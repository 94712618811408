import React from 'react';
import { Container, Stack } from '@mui/material';

const Layout = ({ children }) => {
  return (
    <div>
      <Container maxWidth="sm" sx={{ padding: 2, }}>
        <Stack spacing={2}>
          {children} {/* This is where the page-specific content will be rendered */}
        </Stack>
      </Container>
    </div>
  );
};

export default Layout;

