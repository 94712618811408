import React from 'react';
import { Box, Button, Paper, Stack, Typography, IconButton, Alert, Chip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import UserContactForm from './UserContactForm';

const UserContactsSection = ({ userContacts, contact, setContact, showContactForm, setShowContactForm, saveOrUpdateContact, toggleDrawer }) => {
  return (
    <Paper variant='outlined' style={{ padding: '1rem' }}>
      <Stack>
        <Stack direction="row" justifyContent="space-between" >
          <Stack direction="row" alignItems="center">
            <Typography variant="h6">Contacts</Typography>
            <Typography style={{ color: "gray", paddingLeft: "1rem", fontSize: "0.8rem" }}>~ { userContacts.reduce((acc, contact) => acc + 12/contact.frequency, 0) } rdv par an</Typography>
          </Stack>
          {!showContactForm && (
            <Button variant="contained" size="small" onClick={() => setShowContactForm(true)}>
              + Contact
            </Button>
          )}
        </Stack>
        {showContactForm && (
          <UserContactForm
            contact={contact}
            setContact={setContact}
            onSave={saveOrUpdateContact}
            onCancel={() => setShowContactForm(false)}
          />
        )}
        {userContacts.length < 3 && (
          <Box sx={{ marginTop: '1rem'}}>
            <Alert severity="info">
              Vous avez moins de 3 contacts. Pensez à en ajouter davantage !
            </Alert>
          </Box>
        )}
        {userContacts.map((contact, index) => (
          <Paper variant="outlined" elevation={0} key={index} sx={{ mt: 2, p: 2, position: 'relative', '&:hover button': { display: 'block', } }}>
            <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
              <Typography variant="h7">{contact.name}</Typography>
              <Chip label={`Tous les ${contact.frequency} mois`} />
            </Stack>
            <Stack>
              <Typography variant="p">{contact.email}</Typography>
            </Stack>
            <IconButton
              size="small"
              onClick={(event) => { setContact(contact); toggleDrawer(true)(event); }}
              sx={{ height: "40px", width: "40px", position: 'absolute', right: 16, top: 16, display: 'none', }}
            >
              <EditIcon />
            </IconButton>
          </Paper>
        ))}
      </Stack>
    </Paper>
  );
};

export default UserContactsSection;