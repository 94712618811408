import React, { useState, useEffect } from 'react';
import { styled } from '@mui/system';
import { Stack, Typography } from '@mui/material';

const Circle = styled('div')(({ size, color1, color2, color3 }) => ({
  width: size,
  height: size,
  borderRadius: '50%',
  background: `radial-gradient(circle at 30% 30%, ${color1} 0%, transparent 70%), 
               radial-gradient(circle at 70% 70%, ${color2} 0%, transparent 70%),
               radial-gradient(circle at 30% 70%, ${color3} 0%, transparent 70%)`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: '#fff',
  fontSize: '20px',
}));

function generateRandomString() {
  return Math.random().toString(36).substring(2, 15);
}

const HashedCircle = ({ inputString, size = 100 }) => {
  const [randomString] = useState(() => generateRandomString()); // Generate once on mount
  const [colors, setColors] = useState({ color1: '#000000', color2: '#FFFFFF', color3: '#FF0000' });

  useEffect(() => {
    const effectiveString = inputString || randomString; // Use inputString or fallback to randomString
    const digestMessage = async (message) => {
      const encoder = new TextEncoder();
      const data = encoder.encode(message);
      const hash = await crypto.subtle.digest('SHA-256', data);
      const hashArray = Array.from(new Uint8Array(hash)); // convert buffer to byte array
      const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');

      const opacity = 0.5;
      // Convert hex to RGB
      const hexToRgb = (hex) => {
        const r = parseInt(hex.substring(0, 2), 16);
        const g = parseInt(hex.substring(2, 4), 16);
        const b = parseInt(hex.substring(4, 6), 16);
        return `rgb(${r}, ${g}, ${b}, ${opacity})`;
      };

      const color1 = hexToRgb(hashHex.substring(0, 6));
      const color2 = hexToRgb(hashHex.substring(6, 12));
      const color3 = hexToRgb(hashHex.substring(12, 18));

      return { color1, color2, color3 };
    };

    digestMessage(effectiveString).then(setColors);
  }, [inputString, randomString]); // Depend on inputString and randomString

  return (<Stack>
      <Circle size={size} color1={colors.color1} color2={colors.color2} color3={colors.color3}></Circle>
      <Typography>{inputString || randomString}</Typography>
    </Stack>);
};

export default HashedCircle;