import React, { useState } from 'react';
import { Paper, Stack, Typography, Button } from '@mui/material';
import { useAuth } from './Authprovider';
import EmailDialog from './EmailDialog';
import { red, green } from '@mui/material/colors'
import axios from 'axios';
import LockIcon from '@mui/icons-material/Lock';
import UpdateOutlinedIcon from '@mui/icons-material/UpdateOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';

function getDelayMessage(days_until_target) {
  if (days_until_target <= -37) {
    const months = Math.abs(Math.floor(days_until_target / 30));
    return ` ${months} mois de retard`;
  } else if (days_until_target <= -14) {
    const weeks = Math.abs(Math.floor(days_until_target / 7));
    return ` ${weeks} semaines de retard`;
  } else if (days_until_target <= -7) {
    return `Une semaine de retard`;
  } else if (days_until_target <= 15) {
    return "On est dans les temps";
  } else {
    return "Tu es en avance";
  }
}

const QueueSection = ({ queue, fetchQueue, days, restaurants, limit = 5 }) => {
  const [open, setOpen] = useState(false);
  const [emailBody, setEmailBody] = useState('');
  const [emailTo, setEmailTo] = useState('');
  const [contactName, setContactName ] = useState('');
  const [restaurant, setRestaurant] = useState('');
  const { profile } = useAuth();

  // Mapping of French weekdays to their order
  const dayOrder = {
    "lundi": 1,
    "mardi": 2,
    "mercredi": 3,
    "jeudi": 4,
    "vendredi": 5,
    "samedi": 6,
    "dimanche": 7
  };

  // Sort the days array based on the dayOrder mapping
  const sortedDays = days.sort((a, b) => dayOrder[a.toLowerCase()] - dayOrder[b.toLowerCase()]);

  const handleClickOpen = (lunch, restaurant) => {
    setContactName(lunch.name);
    setEmailTo(lunch.email);
    setEmailBody(`Invitation au `, restaurant, ' à midi.');
    setRestaurant(restaurant);
    setOpen(true);
  };

  const handleConfirm = async (lunch, day, restaurant) => {
    // Mapping of French weekdays to their numeric representation (0-6, Sunday-Saturday)
    const dayMapping = {
      "dimanche": 0,
      "lundi": 1,
      "mardi": 2,
      "mercredi": 3,
      "jeudi": 4,
      "vendredi": 5,
      "samedi": 6,
    };

    const today = new Date();
    const todayDayOfWeek = today.getDay(); // Today's day of week as a number (0-6)
    const targetDayOfWeek = dayMapping[day.toLowerCase()]; // Target day of week as a number (0-6)

    // Calculate difference between today and the target day of the week
    let diff = targetDayOfWeek - todayDayOfWeek;
    if (diff <= 0) {
      diff += 7; // Ensure it's next week
    }

    // Calculate the date of the next occurrence of the target day
    const nextTargetDay = new Date(today);
    nextTargetDay.setDate(today.getDate() + diff);

    // Format the date as "YYYY-MM-DD"
    const eventDate = nextTargetDay.toISOString().split('T')[0];
    
    try {
      const response = await axios.post('/api/events/create', {
          googleId: profile.googleuser.id,
          usercontact_id: lunch.usercontact_id,
          event_date: eventDate, // Now set to the calculated date
          event_place: restaurant,
        }, {
        headers: {
          'Authorization': `Bearer ${profile.tokens?.id_token}`,
        },
        withCredentials: true
      });

      if (response.status === 200) {
        fetchQueue();
      } else {
        console.error('Failed to save event');
      }
    } catch (error) {
      console.error('Error saving event:', error);
    }
  };

  const handlePostpone = async (lunch) => {
    console.log('Postpone logic for', lunch.name);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Paper variant='outlined' style={{ padding: '1rem' }}>
      <Stack>
        <Typography variant="h5">La semaine prochaine...</Typography>
        { queue.slice(0, limit).map((lunch, index) => { 
          const day = sortedDays[index];
          const restaurant = restaurants[index % restaurants.length];
          return (
            <Paper variant="outlined" elevation={0} key={index} sx={{ p:2, mt: 2}}>
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Stack>
                  <Typography variant="h6" style={{color: red[600]}}>{day} : {lunch.name}</Typography>
                  <Typography variant="p">Où ? {lunch.event_place_name || restaurant.name}</Typography>
                  <Typography variant="p">Tous les {lunch.frequency} mois</Typography>
                  <Typography variant="p">{getDelayMessage(lunch.days_until_target)}</Typography>
                </Stack>
                <Stack direction="column" spacing={1}>
                  {lunch.event_place_name ? (
                    <Button variant="outlined" size="small" startIcon={<CheckOutlinedIcon color="green" />} disabled sx={{ borderColor: green[500] }}>
                      <span style={{ color: green[200] }}>Confirmé</span>
                    </Button>
                  ) : (
                    <Button variant="outlined" size="small" onClick={() => handleConfirm(lunch, day, restaurant.google_place_id)}>Confirmer</Button>
                  )}
                  <Button variant="outlined" size="small" onClick={() => handleClickOpen(lunch, restaurant.name)}>L'inviter</Button>
                  <Button variant="outlined" size="small" startIcon={<UpdateOutlinedIcon />} onClick={() => handlePostpone(lunch)}>Plus tard</Button>
                  <Button variant="outlined" size="small" disabled startIcon={<LockIcon />}>Réserver</Button>
                </Stack>
              </Stack>
            </Paper>
          ) 
        })
        }
      </Stack>
      <EmailDialog
        open={open}
        onClose={handleClose}
        contactName={contactName}
        emailTo={emailTo}
        emailBody={emailBody}
        restaurant={restaurant}
      />
    </Paper>
  );
};

export default QueueSection;
