import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { googleLogout } from '@react-oauth/google';
import { Avatar, Box, Button, Paper, Stack, Typography, Drawer, Container, CircularProgress, Slide } from '@mui/material';
import axios from 'axios';
import Layout from '../components/Layout';
import { useAuth } from '../components/Authprovider';
import UserContactForm from '../components/UserContactForm';
import QueueSection from '../components/QueueSection';
import UserContactsSection from '../components/UserContactsSection';
import UserRestaurantsSection from '../components/UserRestaurantsSection';
import UserSettingsSection from '../components/UserSettingsSection';
import EventFeedbackCollection from '../components/EventFeedbackCollection';
import useQueue from '../hooks/useQueue';
import useUserContacts from '../hooks/useUserContacts';
import useUserSettings from '../hooks/useUserSettings';
import useUserRestaurants from '../hooks/useUserRestaurants';
import usePastEvents from '../hooks/usePastEvents';
import DragDropList from '../components/DragDropList';

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL;

const AppPage = () => {
  const { profile, setProfile } = useAuth();
  const navigate = useNavigate();
  const [ isInitialized, setIsInitialized ] = useState(false);

  const [tokens] = useState(() => {
    const tokensInLocalStorage = localStorage.getItem('tokens');
    return tokensInLocalStorage ? JSON.parse(tokensInLocalStorage) : null;
  });

  useEffect(() => {
  
    const initialize = async () => {
      if (!tokens) {
        logOut();
      } else if (tokens.expiry_date < new Date()) {
        try {
          const res = await axios.post('/api/auth/google/refresh-token', {
            access_token: tokens.access_token,
            id_token: tokens.id_token,
          });
          await setProfile({
            ...profile,
            tokens: res.data,
          });
        } catch (error) {
          console.error('Error refreshing token:', error)
          logOut();
        }
      }

      let updatedProfile = profile;

      // Fetch Google user info if not already in profile
      if (!profile.googleuser && profile.tokens.access_token) {
        try {
          const res = await axios.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${profile.tokens.access_token}`, {
            headers: {
              Authorization: `Bearer ${profile.tokens.access_token}`,
              Accept: 'application/json'
            }
          });
          updatedProfile = {
            ...profile,
            googleuser: res.data
          };
          setProfile(updatedProfile);
          localStorage.setItem('profile', JSON.stringify(updatedProfile));
        } catch (error) {
          console.error('Error fetching user data:', error);
          navigate('/');
          return;
        }
      }

      // Check user status if googleuser is in the profile but user info is not
      if (updatedProfile.googleuser && !updatedProfile.user) {
        try {
          const response = await axios.post('/api/user/status', {
            email: updatedProfile.googleuser.email,
            googleId: updatedProfile.googleuser.id,
          }, {
            headers: {
              'Authorization': `Bearer ${profile.tokens?.id_token}`,
            },
            withCredentials: true
          });
          if (response.data) {
            updatedProfile = {
              ...updatedProfile,
              user: response.data
            };
            setProfile(updatedProfile);
            localStorage.setItem('profile', JSON.stringify(updatedProfile));
          }
        } catch (error) {
          console.error('Error checking user status:', error);
          if (error.response && error.response.status === 403) {
            updatedProfile = {
              ...updatedProfile,
              status: 'waitlist'
            };
            setProfile(updatedProfile);
            navigate('/');
            return;
          }
        }
      }

      // Assuming user status check was successful, now fetch user settings
      if (updatedProfile.user) {
        try {
          fetchUserSettings();
          //fetchPastEvents();
        } catch (error) {
          console.error('Error fetching user settings:', error);
        }
      }

      // Init complete, set status to true to show proper UI
      setIsInitialized(true);
    };
    initialize();
  }, [navigate]);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setIsDrawerOpen(open);
  };

  const [contact, setContact] = useState({
    usercontact_id: undefined,
    google_id: '',
    name: '',
    email: '',
    frequency: ''
  });
  const [showContactForm, setShowContactForm] = useState(false);
  const { userContacts, fetchUserContacts } = useUserContacts(profile);
  
  const { userRestaurants, fetchUserRestaurants } = useUserRestaurants(profile);
  
  const { queue, fetchQueue } = useQueue(profile);
  
  const saveOrUpdateContact = async () => {
    const isUpdating = contact?.usercontact_id;
    const endpoint = isUpdating ? '/api/contacts/update' : '/api/contacts/create';
    const method = isUpdating ? 'PUT' : 'POST';
    try {
      const response = await axios({
        method: method,
        url: endpoint,
        data: {
          googleId: profile.googleuser.id,
          userContactData: { ...contact }
        },
        headers: {
          'Authorization': `Bearer ${profile.tokens.id_token}`,
        },
        withCredentials: true,
      });
      if (response.status !== 200) {
        throw new Error('Failed to save contact');
      }
      setContact({ name: '', email: '', frequency: '' });
      setShowContactForm(false); // Hide the form
      setIsDrawerOpen(false);
      fetchUserContacts(); // Refresh the contact list
      fetchQueue();
    } catch (error) {
      console.error('Error saving/updating contact:', error);
    }
  };

  const { userSettings, fetchUserSettings, updateUserSettings } = useUserSettings(profile);

  
  const { pastEvents, fetchPastEvents } = usePastEvents(profile);
  
  // Log out
  const logOut = () => {
    googleLogout();
    localStorage.clear();
    setProfile(null);
    navigate('/');
  };

  // Add state to manage which section is currently visible
  const [visibleSection, setVisibleSection] = useState(null);
  const [transitioning, setTransitioning] = useState(false);

  // Function to handle section change
  const changeSection = (newSection) => {
    if (visibleSection === newSection) {
      setVisibleSection(null); // Hide the section if it's already visible
    } else if (visibleSection === null) {
      setVisibleSection(newSection);
    } else {
      setTransitioning(true); // Start transition
      setVisibleSection(newSection); // Set the new section to be visible
    }
  };

  // Function to handle the end of a transition
  const handleTransitionEnd = () => {
    setTransitioning(false); // End transition
  };

  // Determine what content to show based on the conditions
  let contentToShow;
  if (userContacts.length === 0) {
    contentToShow = 'settings'; // Show only contacts if there are 0 contacts
  } else if (userContacts.length > 0 && (!userSettings.lunchDays || userSettings.lunchDays.length === 0)) {
    contentToShow = 'settings_contacts'; // Show settings and contacts if there are 1 or more contacts but 0 lunchdays
  } else if (userContacts.length > 0 && userSettings.lunchDays && userSettings.lunchDays.length > 0) {
    contentToShow = 'buttons'; // Show buttons to toggle sections if there are 1 or more contacts and 1 or more lunchdays
  }

  if (!isInitialized) {
    return (
      <Layout>
        <Paper variant='outlined' style={{ padding: '1rem' }}>
          <Stack direction="row" alignItems="center" justifyContent="center" spacing={2}>
            <CircularProgress />
            <Typography variant="h6">Chargement</Typography>
          </Stack>
        </Paper>
      </Layout>
    )
  }

  return (
    <Layout>
      
      {/*pastEvents.length > 0 && <EventFeedbackCollection isOpen={true} events={pastEvents} />*/}
      
      <Paper variant='outlined' style={{ padding: '1rem' }}>
        <Stack direction="column" alignItems="stretch" justifyContent="space-around" maxWidth="md">
          <Box>
            <Stack direction="row" alignItems="center" spacing={3}>
              <Avatar src={profile?.googleuser?.picture} alt="user" />
              <Typography variant="h5">{profile?.googleuser?.name}</Typography>
              <Button variant="outlined" size="small" onClick={logOut}>
                Se déconnecter
              </Button>
            </Stack>
          </Box>
        </Stack>
      </Paper>
      
      {/* Conditional rendering based on contentToShow */}
      {contentToShow === 'contacts' && (
        <UserContactsSection
          userContacts={userContacts}
          contact={contact}
          setContact={setContact}
          showContactForm={showContactForm}
          setShowContactForm={setShowContactForm}
          saveOrUpdateContact={saveOrUpdateContact}
          toggleDrawer={toggleDrawer}
        />
      )}

      {contentToShow === 'settings_contacts' && (
        <>
          <UserSettingsSection
            userSettings={userSettings}
            fetchUserSettings={fetchUserSettings}
            updateUserSettings={updateUserSettings} />
          <UserContactsSection
            userContacts={userContacts}
            contact={contact}
            setContact={setContact}
            showContactForm={showContactForm}
            setShowContactForm={setShowContactForm}
            saveOrUpdateContact={saveOrUpdateContact}
            toggleDrawer={toggleDrawer}
          />
        </>
      )}

      {contentToShow === 'buttons' && (
        <>
          {/* Queue component here... */}
          <QueueSection queue={queue} fetchQueue={fetchQueue} days={userSettings.lunchDays} restaurants={userRestaurants} limit={userSettings.lunchDays.length} />

          {/* Toggle buttons */}
          <Stack direction="row" spacing={2} justifyContent="center" sx={{ my: 2 }}>
            <Button variant={ visibleSection==="settings" ? "contained" : "outlined" } onClick={() => changeSection('settings')}>
              Paramètres
            </Button>
            <Button variant={ visibleSection==="contacts" ? "contained" : "outlined" } onClick={() => changeSection('contacts')}>
              Contacts
            </Button>
            <Button variant={ visibleSection==="restaurants" ? "contained" : "outlined" } onClick={() => changeSection('restaurants')}>
              Restaurants
            </Button>
          </Stack>

          {/* Conditionally render sections with slide effect based on visibleSection state */}
          <Slide 
            direction={visibleSection === 'settings' ? "right" : "left"}
            in={visibleSection === 'settings' && !transitioning}
            addEndListener={(node, done) => {
              node.addEventListener('transitionend', done, false);
            }}
            onExited={handleTransitionEnd}
            mountOnEnter unmountOnExit>
            <div>
                <UserSettingsSection
                  userSettings={userSettings}
                  fetchUserSettings={fetchUserSettings}
                  updateUserSettings={updateUserSettings} />
            </div>
          </Slide>

          <Slide 
            direction={visibleSection === 'contacts' ? "right" : "left"}
            in={visibleSection === 'contacts' && !transitioning}
            addEndListener={(node, done) => {
              node.addEventListener('transitionend', done, false);
            }}
            onExited={handleTransitionEnd}
            mountOnEnter unmountOnExit>
            <div>
                <UserContactsSection
                  userContacts={userContacts}
                  contact={contact}
                  setContact={setContact}
                  showContactForm={showContactForm}
                  setShowContactForm={setShowContactForm}
                  saveOrUpdateContact={saveOrUpdateContact}
                  toggleDrawer={toggleDrawer}
                />
            </div>
          </Slide>

          <Slide 
            direction={visibleSection === 'restaurants' ? "right" : "left"}
            in={visibleSection === 'restaurants' && !transitioning}
            addEndListener={(node, done) => {
              node.addEventListener('transitionend', done, false);
            }}
            onExited={handleTransitionEnd}
            mountOnEnter unmountOnExit>
            <div>
                <UserRestaurantsSection 
                  userRestaurants={userRestaurants}
                  fetchUserRestaurants={fetchUserRestaurants}
                />
            </div>
          </Slide>
        </>
      )}

      {/* Keep the Drawer component as is */}
      <Drawer
        anchor={'bottom'}
        open={isDrawerOpen}
        onClose={toggleDrawer(false)}
      >
        <Box >
          <div
            role="presentation"
            onClick={(event) => {
              event.stopPropagation();
            }}
            onKeyDown={(event) => {
              if (event.key === 'Escape') {
                toggleDrawer(false)(event);
              }
            }}
          >
            <Container maxWidth="md">
              <UserContactForm
                contact={contact}
                setContact={setContact}
                onSave={saveOrUpdateContact}
                onCancel={(event) => toggleDrawer(false)(event)}
              />
            </Container>
          </div>
        </Box>
      </Drawer>
    </Layout>
  );
};

export default AppPage;
