import React, { useState } from 'react';
import { Button, TextField, Typography, Paper, Stack, IconButton } from '@mui/material';
import { LoadScript, Autocomplete } from '@react-google-maps/api';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import { useAuth } from './Authprovider';


const UserRestaurants = ({ userRestaurants, fetchUserRestaurants }) => {
  const { profile } = useAuth();
  const [open, setOpen] = useState(false);
  const [autocomplete, setAutocomplete] = useState(null);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleOnLoad = (autocomplete) => {
    setAutocomplete(autocomplete);
  };

  const handlePlaceSelect = () => {
    const place = autocomplete.getPlace();
    const userRestaurant = {
      name: place.name, 
      address: place.formatted_address, 
      google_place_id: place.place_id 
    }
    saveUserRestaurant(userRestaurant);
    handleClose();
  };

  const saveUserRestaurant = async (userRestaurant) => {
    try {
      const response = await axios.post('/api/userrestaurants/create', {
        googleId: profile?.googleuser?.id,
        userRestaurantData: { ...userRestaurant }
      }, {
        headers: {
          'Authorization': `Bearer ${profile.tokens?.id_token}`,
        },
        withCredentials: true,
      });
      if (response.status !== 200) {
        throw new Error('Failed to save userRestaurant');
      }
      fetchUserRestaurants(); // Add this line to refresh the list
    } catch (error) {
      console.error('Error saving restaurant:', error);
    }
  }

  const deleteUserRestaurant = async (userRestaurantId) => {
    try {
      const response = await axios.post(`/api/userrestaurants/delete`, {
        userRestaurantId: userRestaurantId,
        googleId: profile?.googleuser?.id,
      }, {
        headers: {
          'Authorization': `Bearer ${profile.tokens?.id_token}`,
        },
        withCredentials: true,
      });
      if (response.status === 200) {
        // Optionally show a success message
        // Refresh the list of user restaurants
        // You might want to remove the deleted restaurant from the userRestaurants state to update the UI
      }
    } catch (error) {
      console.error('Error deleting restaurant:', error);
    }
  };

  const [libraries] = useState(['places']);

  // Bounds for Paris; search isn't constrainted to this area, but biased towards it
  const bounds = {
    north: 49.0063889,
    south: 48.7727071,
    east: 2.655554,
    west: 2.106972,
  }

  const restaurantEmojis = {
    american_restaurant: '🍲',
    bakery: '🍲',
    barbarbecue_restaurant: '🍲',
    brazilian_restaurant: '🍲',
    breakfast_restaurant: '🍲',
    brunch_restaurant: '🍲',
    cafe: '🍲',
    chinese_restaurant: '🥡', // Takeout box
    coffee_shop: '🍲',
    fast_food_restaurant: '🍲',
    french_restaurant: '🍲',
    greek_restaurant: '🍲',
    hamburger_restaurant: '🍲',
    ice_cream_shop: '🍲',
    indian_restaurant: '🍲',
    indonesian_restaurant: '🍲',
    italian_restaurant: '🍕', // Pizza
    japanese_restaurant: '🍣', // Sushi
    korean_restaurant: '🍲',
    lebanese_restaurant: '🍲',
    meal_delivery: '🍲',
    meal_takeaway: '🍲',
    mediterranean_restaurant: '🍲',
    mexican_restaurant: '🌮', // Taco
    middle_eastern_restaurant: '🍲',
    pizza_restaurant: '🍲',
    ramen_restaurant: '🍲',
    restaurant: '🍲',
    sandwich_shop: '🍲',
    seafood_restaurant: '🍲',
    spanish_restaurant: '🍲',
    steak_house: '🍲',
    sushi_restaurant: '🍲',
    thai_restaurant: '🥞',
    turkish_restaurant: '🍲',
    vegan_restaurant: '🍲',
    vegetarian_restaurant: '🍲',
    vietnamese_restaurant: '🍲',
  };

  const getEmoji = (type) => restaurantEmojis[type] || '';

  return (
    <Paper variant='outlined' style={{ padding: '1rem' }}>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h6">Mes restos</Typography>
        {!open && (
          <Button variant="contained" onClick={handleOpen} size='small'>+ resto</Button>
        )}
        {open && (
          <Button variant="outlined" onClick={handleClose} size='small' color="error">Annuler</Button>
        )}
      </Stack>
      {open && (
        <LoadScript googleMapsApiKey="AIzaSyAOfETVsbkh0JLDu9TTGV71yUEC5N3EgIU" libraries={libraries} loading="async">
          <Autocomplete bounds={bounds} onLoad={handleOnLoad} onPlaceChanged={handlePlaceSelect}>
            <TextField fullWidth label="Chercher un restaurant" />
          </Autocomplete>
        </LoadScript>
      )}
      {userRestaurants && userRestaurants.map((restaurant, index) => {
        const paperStyle = (type) => ({
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'right bottom',
          backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 128 128'%3E%3Ctext y='90%' x='85%' dy='0.35em' text-anchor='middle' font-size='70' fill-opacity='0.5'%3E${getEmoji(type)}%3C/text%3E%3C/svg%3E")`
        });
        return (
          <Paper variant="outlined" elevation={0} key={index} sx={{ mt: 2, p: 2, position: 'relative', '&:hover button': { display: 'block', } }} style={paperStyle(restaurant.type || 'restaurant')}>
            <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
              <Stack>
                <Typography variant="h7" style={{ fontWeight: 'bold' }}>{restaurant.name}</Typography>
                <Typography variant="h7">{restaurant.address}</Typography>
              </Stack>
              <IconButton
                size="small"
                onClick={() => deleteUserRestaurant(restaurant.userrestaurant_id)}
                sx={{ padding: "0", display: 'none', }}
              >
                <DeleteIcon />
              </IconButton>
            </Stack>
          </Paper>
        )}
        )}
    </Paper>
  );
};

export default UserRestaurants;