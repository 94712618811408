import React, { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [authState, setAuthState] = useState(() => {
    // Initialize state from localStorage or set defaults
    const profile = localStorage.getItem('profile');
    const isAuthenticated = !!profile; // Example logic
    return {
      isAuthenticated,
      profile: profile ? JSON.parse(profile) : null,
    };
  });

  useEffect(() => {
    // Sync profile with localStorage
    localStorage.setItem('profile', JSON.stringify(authState.profile));
  }, [authState.profile]);

  const setProfile = (profile) => {
    setAuthState((prevState) => ({
      ...prevState,
      isAuthenticated: !!profile, // Update isAuthenticated based on the presence of the profile
      profile,
    }));
  };

  // Add other authentication methods here

  return (
    <AuthContext.Provider value={{ ...authState, setProfile }}>
      {children}
    </AuthContext.Provider>
  );
};