import React from 'react';
import { AuthProvider } from './components/Authprovider';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage'
import AppPage from './pages/AppPage'
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { UserSettingsProvider } from './components/UserSettingsContext'; 
import { red } from '@mui/material/colors'
import PrivacyPolicy from './pages/PrivacyPage';
import Sandbox from './pages/Sandbox';

const theme = createTheme({
  palette: {
    primary: {
      main: red[500],
    }
  },
  typography: {
    h6: {
      fontWeight: 800,
    }
  } 
});

function App() {
  return (
    <Router>
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <UserSettingsProvider>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/app" element={<AppPage />} />
              <Route path='/privacy-policy' element={<PrivacyPolicy />} />
              <Route path='/sandbox' element={<Sandbox />} />
            </Routes>
          </UserSettingsProvider>
        </ThemeProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;
