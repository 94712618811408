import React, { useState, useEffect } from 'react';
import Layout from '../components/Layout';
import { Paper, Stack, Typography, ThemeProvider, createTheme } from '@mui/material';
import ProfileImage from '../components/ProfileImage';

// Create a theme instance and customize the Typography variant
const theme = createTheme({
  typography: {
    h6: {
      color: 'linear-gradient(90deg, #FE6B8B 30%, #FF8E53 90%)', // Gradient color
      background: '-webkit-linear-gradient(90deg, #FE6B8B 30%, #FF8E53 90%)',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent'
    }
  }
});

const Sandbox = () => {
  const [restaurantType] = useState('mexican_restaurant');

  // Mapping of restaurant types to emojis
  const restaurantEmojis = {
    american_restaurant: '🍲',
    bakery: '🍲',
    barbarbecue_restaurant: '🍲',
    brazilian_restaurant: '🍲',
    breakfast_restaurant: '🍲',
    brunch_restaurant: '🍲',
    cafe: '🍲',
    chinese_restaurant: '🥡', // Takeout box
    coffee_shop: '🍲',
    fast_food_restaurant: '🍲',
    french_restaurant: '🍲',
    greek_restaurant: '🍲',
    hamburger_restaurant: '🍲',
    ice_cream_shop: '🍲',
    indian_restaurant: '🍲',
    indonesian_restaurant: '🍲',
    italian_restaurant: '🍕', // Pizza
    japanese_restaurant: '🍣', // Sushi
    korean_restaurant: '🍲',
    lebanese_restaurant: '🍲',
    meal_delivery: '🍲',
    meal_takeaway: '🍲',
    mediterranean_restaurant: '🍲',
    mexican_restaurant: '🌮', // Taco
    middle_eastern_restaurant: '🍲',
    pizza_restaurant: '🍲',
    ramen_restaurant: '🍲',
    restaurant: '🍲',
    sandwich_shop: '🍲',
    seafood_restaurant: '🍲',
    spanish_restaurant: '🍲',
    steak_house: '🍲',
    sushi_restaurant: '🍲',
    thai_restaurant: '🥞',
    turkish_restaurant: '🍲',
    vegan_restaurant: '🍲',
    vegetarian_restaurant: '🍲',
    vietnamese_restaurant: '🍲',
  };

  const response = 'defaut';

  useEffect(() => {
    const fetchRestaurantType = async () => {
      try {
        //response = await axios.get('');
        //setRestaurantType(response.data.restaurantType);
      } catch (error) {
        console.error('Failed to fetch restaurant type:', error);

      }
    };

    fetchRestaurantType();
  }, []);

  const getEmoji = (type) => restaurantEmojis[type] || '';

  const paperStyle = {
    padding: '1rem',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right -15% bottom -20%',
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 128 128'%3E%3Ctext y='90%' x='85%' dy='0.35em' text-anchor='middle' font-size='50' fill-opacity='0.5'%3E${getEmoji(restaurantType)}%3C/text%3E%3C/svg%3E")`
  };

  return (
    <ThemeProvider theme={theme}>
      <Layout>
        <Paper variant='outlined' style={paperStyle}>
          <Typography variant='h6'>
            Sandbox
          </Typography>
          <Stack>
            <Typography variant='p'>Some text</Typography>
            <Typography variant='p'>Some text</Typography>
            <Typography variant='p'>Some text</Typography>
            <Typography variant='p'>Some text</Typography>
            <Typography variant='p'>Some text</Typography>
            <Typography variant='p'>Some text</Typography>
            <Typography variant='p'>Some text</Typography>
            <Typography variant='p'>{response.data}</Typography>
          </Stack>
        </Paper>
        <Paper>
          <ProfileImage />
          <ProfileImage />
          <ProfileImage />
        </Paper>
      </Layout>
    </ThemeProvider>
  );
};

export default Sandbox;

