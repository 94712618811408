import { useState, useEffect } from 'react';
import axios from 'axios';

const useQueue = (profile) => {
  const [queue, setQueue] = useState([]);

  const fetchQueue = async () => {
    if (!profile?.googleuser?.id) return;

    try {
      const response = await axios.post('/api/queue', {
        googleId: profile.googleuser.id,
      }, {
        headers: {
          'Authorization': `Bearer ${profile.tokens.id_token}`, // Adjust according to your token key names
        },
        withCredentials: true,
      });
      setQueue(response.data);
    } catch (error) {
      console.error('Error fetching queue:', error);
    }
  };

  useEffect(() => {
    fetchQueue();
  }, [profile, profile.tokens]);

  return { queue, fetchQueue };
};

export default useQueue;