import { useContext, useEffect } from 'react';
import { UserSettingsContext } from '../components/UserSettingsContext';
import axios from 'axios';

const useUserSettings = (profile) => {
  const { userSettings, setUserSettings } = useContext(UserSettingsContext);

  const fetchUserSettings = async () => {
    if (profile?.googleuser?.id) {
      try {
        const response = await axios.get('/api/user/settings', {
          params: { googleId: profile?.googleuser?.id },
          headers: {
            'Authorization': `Bearer ${profile.tokens?.id_token}`,
          },
          withCredentials: true,
        });
        if (response.status === 200) {
          setUserSettings({
            officeAddress: response.data.office_address || '', // Fallback to empty string if falsy
            lunchDays: response.data.lunch_days ? response.data.lunch_days.split(',') : [], // Check if lunch_days is not empty
          });
        };
      } catch (error) {
        console.error('Error fetching user settings:', error);
      }
    }
  };

  const updateUserSettings = async (newSettings) => {
    if (profile?.googleuser?.id) {
      try {
        const response = await axios.post('/api/user/settings', {
          googleId: profile?.googleuser?.id,
          officeAddress: newSettings.officeAddress,
          lunchDays: newSettings.lunchDays.join(','), // Convert array to comma-separated string
        }, {
          headers: {
            'Authorization': `Bearer ${profile.tokens?.id_token}`,
          },
          withCredentials: true,
        });
        if (response.status === 200) {
          setUserSettings({
            officeAddress: response.data.office_address || '', // Fallback to empty string if falsy
            lunchDays: response.data.lunch_days ? response.data.lunch_days.split(',') : [], // Check if lunch_days is not empty
          });
        };
      } catch (error) {
        console.error('Error updating user settings:', error);
      }
    }
  };

  useEffect (() => {
    if (profile?.googleuser?.id && profile?.tokens?.id_token) {
      fetchUserSettings();
    }
  }, [profile]);

  return { userSettings, fetchUserSettings, updateUserSettings };
};

export default useUserSettings;
